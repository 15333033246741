.scheme__left-links>li {
    padding: 5px 0;
}

.scheme__left-links>li>a {
    transition: all 400ms ease;
    cursor: pointer;
}

.scheme__left-links>li>a:hover {
    color: #f36d21;
}

.scheme__heading {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 45px 0 30px 0;
    text-align: center;
}

.scheme__sub-heading {
    color: #008000;
    font-family: Verdana, sans-serif;
    font-size: 1rem;
    margin: 20px 0 10px 0;
}

.scheme div ul>li {
    list-style: circle;
    margin-left: 4rem;
}

.scheme__button {
    border-radius: 5px;
    padding: 8px 20px;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(-180deg, #00D775, #00BD68);
    cursor: pointer;
}

.scheme__button:hover {
    background: #00bd68;
}

.schema__our-students {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #083973;
    font-weight: 600;
    cursor: pointer;
    margin: 20px 0;
}

.schema__our-students:hover span {
    text-decoration: underline;
}