.loader__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
  }
  
  .loader {
    width: 64px;
    height: 64px;
    position: relative;
    animation: spin 1.6s linear infinite;
  }
  
  .loader__bar {
    width: 6px;
    height: 18px;
    background: #FFF;
    position: absolute;
    left: 29px; /* Center the bars horizontally */
    top: 0;
    transform-origin: 3px 32px; /* Adjust according to bar width and loader size */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  