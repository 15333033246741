@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

.login {
    background-image: url('../../assets/login-background.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Source Sans 3", sans-serif;
}

.login::before {
    background-color: rgba(19, 32, 37, .85);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.login__logo {
    background: url('../../assets/login-logo.png') no-repeat 50%;
    background-size: contain;
    height: 60px;
}

.login__header {
    font-family: "Titillium Web", sans-serif;
}

.login__form input {
    /* border: 1px solid var(--color-login-labels); */
    border-radius: 3px;
}

.login__form input:hover {
    border-color: var(--color-login);
}

.login__form input[type='number']::-webkit-outer-spin-button,
.login__form input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}