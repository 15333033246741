nav.app__header>div>ul li {
    background-color: #9d37d1;
    color: white;
    padding: 4px 6px;
    margin-top: -25px;
    margin-right: 10px;
    border-radius: 5px;
}

nav.app__header>div>ul>li:hover {
    background-color: indianred;
}

nav.app__header div:last-of-type p::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: 0 10px 15px 10px;
    position: absolute;
    top: -10px;
    right: 10px;
    border-color: transparent transparent indianred transparent;
    transform: rotate(0deg);
}

.secondary-navbar>ul {
    display: flex;
}

.secondary-navbar>ul>li {
    position: relative;
}

.secondary-navbar ul li p {
    color: var(--color-primary);
    padding: 10px 14px;
    font-weight: bold;
    font-size: 9pt;
    transition: all 400ms ease;
}

.secondary-navbar ul li p:hover {
    background-color: var(--color-primary);
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.secondary-navbar>ul>li>div {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 57%, rgba(221, 221, 221, 1) 100%);
    border: 2px solid #CCC;
    width: max-content;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 400ms ease;
}

.secondary-navbar>ul>li>div:hover {
    display: block;
}

.secondary-navbar>ul>li>p:hover+div {
    display: block;
}

.secondary-navbar>ul>li>div>ul {
    padding: .6rem 1rem;
}

.secondary-navbar>ul>li>div>ul>li {
    border-left: 1px solid #8fb0cc;
}

.secondary-navbar>ul>li>div>ul>li>a {
    padding-left: 10px;
    font-size: 12px;
    cursor: pointer;
}

.secondary-navbar>ul>li>div>ul>li>a:hover {
    color: var(--color-secondary);
    transition: all 400ms ease;

}

.users__tooltip:hover::before {
    content: "Users Login";
}

.sitemap__tooltip:hover::before {
    content: "Sitemap";
}

.home__tooltip:hover::before {
    content: "Home";
}