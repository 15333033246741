

footer.footer > div:nth-child(2) {
    display: flex;
    width: 100%;
    column-gap: 40px;
}


footer.footer > div:nth-child(2) > div > p {
    font-size: 11pt;
    font-weight: bold;
    border-bottom: 3px solid #74983e;
    padding: 10px 7px;
}

footer.footer > div:nth-child(2) > div > ul > li > a {
    display: flex;
    margin: 10px 0;
    line-height: 12pt !important;
    font: 8pt Tahoma, Arial, sans-serif;
    cursor: pointer;
    background: url("../../assets/bullet.png") left center no-repeat;
    padding-left: .8rem;
    transition: all 400ms ease;
}

footer.footer > div:nth-child(2) > div > ul > li > a:hover {
    color: var(--color-secondary);
}

footer.footer > div:last-child {
    font: 8pt Tahoma, Arial, sans-serif;
    flex-direction: column;
    padding: 22px;
}

footer.footer > div:last-child > ul > li {
    cursor: pointer;
}


.linkedin__tooltip:hover::before {
    content: 'linkedin';
}