.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip:hover::before {
    position: absolute;
    width: max-content;
    top: 120%;
    left: 50%;
    z-index: 120;
    background-color: #01AA95;
    padding: 8px 12px;
    font-size: 12px;
}