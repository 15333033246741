.our-students__tag {
    color: white;
    background-color: black;
    padding: 4px 12px;
    border-radius: 10px;
    font-size: 14px;
    margin: 10px 0;
    letter-spacing: 0.5px;
}

.our-students__score tr td,
.our-students__score tr th {
    padding: 8px 20px 0 0;
    text-align: center;
}

.our-students__score>tr>th {
    padding-bottom: 15px;
    border-bottom: 1px solid #52796f;
}

.our-students__attendance>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.our-students__attendance>div>p:nth-child(2) {
    background-color: rgb(113, 160, 113);
    border-radius: 99px;
    width: 100px;
    height: 8px;
}

/* .our-students__attendance > div > p:nth-child(2)::after {
    content: "";
    display: block;
    width: 10%;
    height: 100%;
    background-color: #006a00; 
} */

/* Chrome, Safari, Edge, Opera */
.our-students input[type=number]::-webkit-outer-spin-button,
.our-students input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.our-students input[type=number] {
    -moz-appearance: textfield;
}

.research-papers__button {
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.papers__button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.papers__button:focus-visible {
    box-shadow: none;
}